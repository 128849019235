<template>
  <div class="container bg-e0 pm-0" v-if="!indexPage">
    <div class="row">
      <div class="col-sm-12">
        <div class="pt-2 pl-2">
          <AuctionsFilters @filters-changed="setFilters"/>
        </div>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-12">
        <h5 class="pl-2">{{ title }}</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <p class="pm-0 pl-2">
          <span class="pr-1">العدد:</span>
          <span class="link pr-1">{{ total ? total : 0 }}</span>
          <span>مزاد</span>
        </p>
      </div>
      <div class="col-sm-6 d-flex justify-content-end">
        <div class="pb-2 pr-2">
          <button class="btn btn-sm btn-info rounded-0 p-1" @click="setList">
            <span>إعادة تحميل</span>&nbsp;<i class="fa fa-sync"></i>
          </button>
        </div>

        <div class="pb-2 pr-2" v-if="create">
          <router-link class="btn btn-sm btn-info rounded-0 p-1" :to="{name: 'auctions.create'}">
            <span>إضافة مزاد</span>&nbsp;<i class="fa fa-plus"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12" v-if="indexPage">
      <div class="row">
        <div class="col-sm-6">
              <label class="link">نوع المركبة</label>
              <BootstrapSelect :options="manufacturers" id="manufacturer" title="نوع المركبة"
                               select-class="filter-inputs" @changed="changeManufacturer"/>
            </div>
            <div class="col-sm-6">
              <label class="link">موديل المركبة</label>
              <BootstrapSelect id="model" title="موديل المركبة" select-class="filter-inputs" :options="models"
                               @changed="changeModel"/>
            </div>
      </div>
    </div>
    
    <div class="col-sm-12">
      <div class="col-sm-12 jumbotron bg-e0 text-center" v-if="emptyListByValue">
        <h4 class="text-center">لا يوجد مزادات</h4>
      </div>
      <AuctionsListItem
          v-else
          v-for="auction in list" :key="auction.id"
          :auction="auction"
          @invoice-checked="invoiceChecked"
          @restored="restored"
      />
    </div>

    <div class="col-sm-12" v-if="hasAuctionAssigning">
      <hr>
      <h3 class="text-center">مزادات تم اعادة تعيينها</h3>
      <div class="alert alert-success space-x-1" role="alert" v-for="(item, index) in auctionAssigning" :key="index">
        <label>قام المستخدم</label>
        <label class="font-weight-bold">{{ item.creator }}</label>
        <label>بإعادة تعيين المزاد رقم </label>
        <label class="font-weight-bold">#{{ item.auction_id }}</label>
        <label>من المستخدم</label>
        <label class="font-weight-bold">#{{ item.user_from }}</label>
        <label>الى المستخدم</label>
        <label class="font-weight-bold">#{{ item.user_to }}</label>
        <label>بتاريخ</label>
        <label>{{ item.created_at}}</label>
        
        <!-- {{ `قام المستخدم ${item.creator} بإعادة تعيين المزاد رقم #${item.auction_id} من المستخدم ${item.user_from} الى المستخدم ${item.user_to}` }} -->
        <!-- {{  item.type }} -->
      </div>
    </div>
  </div>
  <div class="row" v-if="!emptyList">
    <div class="col-sm-12">
      <ThePaginator
          class="bg-e0"
          :total="total"
          :url="defaultUrl"
          :links="links"
          :last-page="lastPage"
          :default-per-page="perPage"
          @link-changed="setLink"
      />
    </div>
  </div>
</template>

<script>
import pagination from "@/mixins/pagination"
import ThePaginator from "@/components/UI/ThePaginator"
import AuctionsListItem from "@/components/Auctions/AuctionsListItem"
import AuctionsFilters from "@/components/Auctions/AuctionsFilters"
import arrays from '@/common/arrays'
import BootstrapSelect from "@/components/Forms/BootstrapSelect.vue";
import cars from "@/mixins/cars";

export default {
  components: {BootstrapSelect, AuctionsFilters, AuctionsListItem, ThePaginator},
  mixins: [pagination, cars],
  props: {
    type: {required: true},
    create: {type: Boolean, default: false},
    user: {},
  },
  data() {
    return {
      category: '',
      manufacturer: '',
      model: '',
      auctionAssigning: []
    };
  },
  provide() {
    return {
      adminType: this.auctionsListPage || this.adminUserAuction,
      userType: this.indexPage || this.userAuctions,
      user: this.user,
    };
  },
  computed: {
    title() {
      return messages.auctions[this.$route.params.type]
    },
    defaultUrl() {
      return `auctions-list/${this.type}`
    },
    indexPage() {
      return this.$route.name === 'auctions.index'
    },
    auctionsListPage() {
      // the list of all auctions (admin)
      return !['index', 'auctions.index', 'user.auctions', 'users.auctions'].includes(this.$route.name)
    },
    adminUserAuction() {
      // the list of all auctions (admin)
      return this.$route.name === 'users.auctions'
    },
    userAuctions() {
      // the auctions that the logged-in user can show on his dashboard
      return this.$route.name === 'user.auctions'
    },
    formData() {
      return {
        category: this.category,
        user_id: this.user,
        manufacturer: this.manufacturer,
        model: this.model,
      };
    },
    shouldReload() {
      return this.$store.getters.shouldReload
    },
    hasAuctionAssigning() {
      if (this.$route.name !== 'user.auctions.user-stumbled' || this.indexPage || !this.auctionAssigning) {
        return false;
      }

      return this.auctionAssigning.length > 0
    }
  },
  methods: {
    async invoiceChecked() {
      // await this.$refs.categories.setCategories();
    },
    restored(id) {
      this.list = arrays.removeById(this.list, id)
      if (this.total - 1 <= 0) {
        this.total--;
      }
    },
    async changeManufacturer(id) {
      await this.setModelsList(id)
      this.manufacturer = this.setManufacturerName(id);
    },
    changeModel(id) {
      this.model = this.setModelName(id);
    },
    async setAuctionAssigning() {
      const response = await http.send(`auction-assigning/${this.user}`)
      if (http.responseAccepted(response)) {
        this.auctionAssigning = response.data.auction_assigning
      }
    }
  },
  async created() {
    // THIS METHOD WILL CHANGE THE "formData" THEN THE WATCHER WILL GET THE LIST OF DATA
    this.perPage = this.indexPage ? 100 : 25
    const response = await this.setList()
    await this.setManufacturerList()

    if(response?.data?.auction_assigning) {
      this.auctionAssigning = response.data.auction_assigning
    }
  }
}
</script>
